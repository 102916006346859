// Generated by Framer (b75150a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./gGbKlar_q-0.js";

const cycleOrder = ["m9af96r63"];

const serializationHash = "framer-c1pxt"

const variantClassNames = {m9af96r63: "framer-v-1o86qar"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {VMMHDo1EA: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, title, width, ...props}) => { return {...props, CEkxGW1wT: title ?? props.CEkxGW1wT ?? "Installation"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, CEkxGW1wT, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "m9af96r63", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1o86qar", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"m9af96r63"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.h1 style={{"--font-selector": "R0Y7SW50ZXItNzAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "58px", "--framer-font-weight": "700", "--framer-text-color": "var(--extracted-gdpscs, rgb(255, 255, 255))"}}>Installation</motion.h1></React.Fragment>} className={"framer-y6ubdn"} fonts={["GF;Inter-700"]} layoutDependency={layoutDependency} layoutId={"BoFeeckVC"} style={{"--extracted-gdpscs": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={CEkxGW1wT} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-c1pxt.framer-a85tlj, .framer-c1pxt .framer-a85tlj { display: block; }", ".framer-c1pxt.framer-1o86qar { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 16px 0px; position: relative; width: 744px; }", ".framer-c1pxt .framer-y6ubdn { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-c1pxt.framer-1o86qar { gap: 0px; } .framer-c1pxt.framer-1o86qar > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-c1pxt.framer-1o86qar > :first-child { margin-top: 0px; } .framer-c1pxt.framer-1o86qar > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 85.5
 * @framerIntrinsicWidth 744
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"CEkxGW1wT":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramergGbKlar_q: React.ComponentType<Props> = withCSS(Component, css, "framer-c1pxt") as typeof Component;
export default FramergGbKlar_q;

FramergGbKlar_q.displayName = "h1 container";

FramergGbKlar_q.defaultProps = {height: 85.5, width: 744};

addPropertyControls(FramergGbKlar_q, {CEkxGW1wT: {defaultValue: "Installation", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramergGbKlar_q, [{explicitInter: true, fonts: [{family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZ1rib2Bg-4.woff2", weight: "700"}]}], {supportsExplicitInterCodegen: true})